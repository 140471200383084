<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

            <!-- Dashboard Box -->
        <b-card class="mb-0" v-if="display==true">
                <!-- Table Top -->
        <b-link class="brand-logo">
          <!-- <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            EL Taskforce
          </h2> -->

          <b-img
            :src="appLogoImage"
            alt="logo"
            style="width:240px"
          />

        </b-link>
        <b-card-title class="mb-1">
          Set password for your account
        </b-card-title>
          <b-form
            class="auth-login-form mt-2"
          >
        <b-row>

        <b-col cols="12" md="12">
            <b-form-group label="Username" class="" label-for="username">
                <b-form-input type="text" v-model="username" disabled class="form-control-merge"/>
            </b-form-group>
         </b-col>

        <b-col cols="12" md="12">
                        <b-form-group label="Password" class="" label-for="password">
                            <b-form-input placeholder="Enter Password" type="password" v-model="new_password" autofocus autocomplete="off"/>
                        </b-form-group>
                    </b-col>

                      <b-col cols="12" md="12">
                        <b-form-group label="Re-enter Password" class="" label-for="re-password">
                            <b-form-input placeholder="Re-enter Password" type="password" v-model="repeat_new_password" class="form-control-merge" autocomplete="off"/>
                        </b-form-group>
                    </b-col>

                </b-row>

                <b-row>

            <b-col cols="12" sm="12" class="d-flex align-items-center justify-content-center">
        <b-button variant="warning" class="mt-1" @click="setPassword">
            <span class="text-nowrap">Submit</span> 
        </b-button>
            </b-col>

                </b-row>
          </b-form>

        </b-card>

        <div class="container" v-else >
            <div class="row">
                <div class="col-xl-5 offset-xl-3">

                        <!-- Welcome Text -->
                        <div style="text-align:left; font-size:40px; color:red;" >
                            <b-card class="mb-0">
                                <b-row>
                            <span>{{link_error}}</span>
                                </b-row>
                            </b-card>
                        </div>
                </div>
            </div>
        </div>
            
    </div>
  </div>
</template>

<script>
import {
    BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BFormDatepicker,BFormSelect,BFormSelectOption,BModal,BBreadcrumb, BFormGroup, BForm, BAlert, BImg, BCardTitle
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import moment from "moment";
import Bus from "../../../event-bus";

export default {
    components: {
        BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, vSelect, BFormDatepicker,BFormSelect,BFormSelectOption,BModal,BBreadcrumb, BFormGroup, BForm, BAlert, BImg, BCardTitle
    },
    directives: {
        'b-tooltip': VBTooltip
    },
    data() {
        return {
            appLogoImage : require('@/assets/images/logo/task-force-full.png'),
            webUrl: process.env.VUE_APP_SERVER_URL,
            error_message: null, 
            showDismissibleAlert:false,

            token: this.$route.params.token,
            username: '',
            new_password: '',
            repeat_new_password: '',
            display:false,
            link_error:'',
        }
    },
    computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
    methods : {
        setPassword() {
                this.$store.dispatch(POST_API, {
                    data:{
                        user_name: this.username,
                        new_password: this.new_password,
                        repeat_new_password: this.repeat_new_password,
                    },
                    api:'/api/set-password'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                             
                    } else {
                        this.new_password = "";
                        this.repeat_new_password = "";
                    
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            iconColor:'#4c8bf5',
                            title: 'Password successfully set',
                            showConfirmButton: false,
                            timer: 1500
                        }).then((result) => {
                            window.location.replace('/login');
                        });
                    }        
                }); 
        },
        checkToken() {
                this.$store.dispatch(POST_API, {
                    data:{
                        token: this.token,
                    },
                    api:'/api/checktoken'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                             
                    } else {
                    if (this.$store.getters.getResults.data !== null)
                    {
                         this.display = true;
                         this.username = this.$store.getters.getResults.data[0].user_name
                    }
                    else {
                        this.display = false;
                        this.link_error = "Your link has expired."
                    }
            }
            })
        },      
    },
    
    mounted(){
        this.checkToken();
    }
}
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
